import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

export const client = sanityClient({
    projectId: 'v9uaje5y',
    dataset: 'production',
    apiVersion: '2022-02-01',
    useCdn: true,
    token: process.env.REACT_APP__SANITY_TOKEN
});

const builder = imageUrlBuilder(client);
export const urlFor = (source) => {
    const ref = source.asset._ref;

    if (source._type === 'image') {
        return builder.image(source).url(); // Para imágenes.
    } else if (source._type === 'file') {
        const [, id, extension] = ref.split('-'); // Extraer ID y extensión.
        return `https://cdn.sanity.io/files/${client.config().projectId}/${client.config().dataset}/${id}.${extension}`; // URL para archivos.
    } else if (source._type === 'video') {
        const [, id, extension] = ref.split('-'); // Extraer ID y extensión.
        return `https://cdn.sanity.io/files/${client.config().projectId}/${client.config().dataset}/${id}.${extension}`; // URL para videos.
    }
};

